import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Error from "./pages/error";
import Login from "./pages/login";
import { useUserState } from "./context/UserContext";
import RedirectPage from "./pages/redirectPage/RedirectPage";
import NewLogin from "./pages/login/NewLogin";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

export default function App() {
  const { isAuthenticated } = useUserState();

  return (
    <HashRouter>
      <Switch>
        <RedirectRoute
          path="/app/:dashboardType/:deviceId/:token/org/:org/user/:user"
          component={RedirectPage}
        />
        <RedirectRoute
          path="/app/:dashboardType/:token/org/:org/user/:user"
          component={RedirectPage}
        />
        <RedirectRoute
          path="/app/:dashboardType/:token/org/:org/user/:user/type/:type"
          component={RedirectPage}
        />
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={NewLogin} />
        <PublicRoute path="/oldlogin" component={Login} />
        <PublicRoute
          path="/reset-password/uid/:UID/token/:token"
          component={ResetPassword}
        />
        <PublicRoute path="/verify-email/:emailToken" component={VerifyEmail} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );
  // #######################################################################
  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
  function RedirectRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect to={{ pathname: "/" }} />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
