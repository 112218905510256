import { makeStyles } from "@mui/styles";
//import { alpha } from '@mui/styles'

export default makeStyles((theme) => ({
  logotype: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbar: {
    paddingLeft: "24px",
    paddingRight: "4px",
    //backgroundColor: "#2D4D76 !important"
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: 25,
    paddingLeft: "5px",
    width: 36,
    //backgroundColor: alpha(theme.palette.common.black, 0),
    //transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      //backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    //backgroundColor: alpha(theme.palette.common.black, 0.08),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: 250,
    // },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchIconOpened: {
    right: "4px",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36,
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    marginTop: "8px",
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      color: "white",
    },
  },
  headerMenuButton: {
    marginLeft: "4px",
    padding: "2px",
  },
  headerMenuButtonCollapse: {
    marginRight: "4px",
  },
  headerIcon: {
    fontSize: 28,
    color: "rgba(255, 255, 255, 0.35)",
  },
  headerIconCollapse: {
    color: "white",
  },
  profileMenu: {
    minWidth: 215,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  profileMenuItem: {
    color: "blue",
  },
  profileMenuIcon: {
    marginRight: "4px",
    color: "blue",
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  profileMenuLinkWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "flex-end",
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "4px",
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: "4px",
  },
  deviceCount: {
    // backgrounColor: 'rgb(94, 223, 255)',
    boxShadow: "rgba(66, 98, 98, 0.64) 0px 2px 5px",
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    textAlign: "center",
    padding: "10px 0",
    color: "#426262",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "14px",
    cursor: "default",
    marginLeft: "10px",
  },
}));
