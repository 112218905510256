import defaultTheme from "./default";
import { createTheme } from "@mui/material/styles";

const overrides = {
  palette: {
    mode: 'light',
    primary: {
      main: '#001971',
      light: '#00ffeb',
      dark: '#3900e9',
    },
    secondary: {
      main: '#3900E9',
    },
    
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
    MuiButton: {
      size: 'small',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiFab: {
      size: 'small',
    },
    MuiFormControl: {
      margin: 'dense',
      size: 'small',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiRadio: {
      size: 'small',
    },
    MuiSwitch: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
      size: 'small',
    },
    MuiTooltip: {
      arrow: true,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Poppins',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      // fontSize: "1.385rem",
      //textTransform: "capitalize",
      fontFamily: "Poppins",
      //fontSize: "18px",
      fontWeight: "bold",
      //marginTop : "1%",
      fontSize: "1.242rem",
      //color: "#595959",
      //lineHeight : "22px",
      //textTransform : "uppercase"
    },
    h6: {
      fontSize: "0.9rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(135deg,rgba(0,0,0,1), rgba(0,25,113, 1))',
          border: 0,
          borderRadius: 10,
          boxShadow: '0 2px 3px 2px rgba(25, 25, 25, .3)',
          color: 'white',
          height: 48,
          padding: '0 30px',
          // Disabled state styles
          '&.Mui-disabled': {
            background: 'linear-gradient(135deg, rgba(150,150,150,1), rgba(200,200,200,1))', // Grey gradient for disabled state
            color: 'rgba(255, 255, 255, 0.7)', // Slightly transparent white color
            boxShadow: 'none',  // Remove shadow when disabled
          },
        },
      },
    },
  },
};

const items = {
  default: createTheme({
    ...defaultTheme,
    ...overrides,
  }),
};

export default items;
