import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@mui/material";
import { useTheme } from "@mui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
import * as Icons from "@mui/icons-material";
import axios from "axios";
import { useUserDispatch, signOut } from "../../context/UserContext";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, type: 'divider' },
  { id: 1, label: 'Dashboard', link: '/app/dashboard', icon: <Icons.Dashboard style={{color:"white"}}/> },
  // { id: 1, type: 'divider' },
  { id: 2, label: 'Org Management', link: '/app/OrgManagement', icon: <Icons.Storage style={{color:"white"}} /> },
  // { id: 3, type: 'divider' },
  { id: 3, label: 'User Management', link: '/app/UserManagementEnterprise', icon: <Icons.SupervisorAccount style={{color:"white"}} /> },
  // { id: 3, label: 'Logout', link: '/login', icon: <Icons.ExitToApp />},
  { id: 4, type: 'divider' }



  // { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  // { id: 1, label: "Org Management", link: "/app/OrgManagement", icon: <HomeIcon /> },

  // {
  //   id: 1,
  //   label: "Typography",
  //   link: "/app/typography",
  //   icon: <TypographyIcon />,
  // },
  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 3,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 4,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 5, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },
  // { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  // { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  // { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />,
  // },
];
const signoutStructure = [
  { id: 5, label: 'Logout', link: '/login', icon: <Icons.ExitToApp style={{color:"white"}}/> }

]

function Sidebar({ location }, props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var layoutState = useLayoutState();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var isAlertShown = false
  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  axios.interceptors.response.use((response) => {
    // Return a successful response back to the calling service
    return response;
  }, (error) => {
    // console.log(error);
    if (error && error.response && error.response.status === 401) {
      if (isAlertShown === false) {
        isAlertShown = true
        alert("Signature expired");
        signOut(userDispatch, history)
      }
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  });

  return (

    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      {/* <div className={classes.toolbar} /> */}
      <div className={classes.mobileBackButton}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}>
          {layoutState.isSidebarOpened ? (
            <Icons.MenuOpen
              style={{color:"white"}}
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
              <Icons.Dehaze
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }} style={{ color: "white" }}
              />
            )}
        </IconButton>

      </div>
      {/* 
      <div className={classes.toolbar} />
      {isSidebarOpened === true ?
        <div className={classes.sidebarList}>
          <img style={{ marginLeft: "35%" }} src="SpaceAge-Icon-Grayscale.png" width="75" height="90" />
          <Typography style={{ marginTop: "5%", fontFamily: "'Karla', sans-serif", textAlign: "center", fontWeight: "bold" }} >Hi, {sessionStorage.getItem("first_name")}</Typography>
        </div>
        : null} */}

      <div />
      {isSidebarOpened === true ?
        <div style={{ marginTop: "5%" }} className={classes.sidebarList}>
          <img style={{ marginLeft: "15%" }} src="SpaceAge-Icon-Grayscale.png" width="35" height="40" />
          <Typography style={{ marginTop: "5%", color: "white",  float: "right", marginRight: "25%", fontFamily: "'Karla', sans-serif", fontWeight: "bold" }} >Hi, {sessionStorage.getItem("first_name")}</Typography>
        </div>
        : null}

      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
      <List className={classes.sidebarList} onClick={() => signOut(userDispatch, history)}>
        {signoutStructure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
      {/* <div className={classes.sidebarList}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div> */}
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
