import React, { useState, useEffect, useCallback } from "react";
import { AppBar, Toolbar, IconButton, Tooltip, Menu, Box } from "@mui/material";
import { Menu as MenuIcon, Person as AccountIcon } from "@mui/icons-material";
// import moment from "moment";
import classNames from "classnames";
import useStyles from "./styles";
import { Typography } from "../Wrappers/Wrappers";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useDispatch, useSelector, useStore } from "react-redux";
import { APIStatus } from "../../app/APIStatus";
import { getDashboardConfig } from "../../app/configurationSlice";
import { getOrgBillingInfo } from "../../app/billingInfoSlice";
import { whitelabelConfig } from "../../whitelabelConfig/whitelabelConfig";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import logo from "./Logo-TOP Clear Teal.png";

import RebrandingBanner from "../RebrandingBanner/RebrandingBanner";

const ACCESS_TOKEN_EXPIRES_TIME = 1800000; // 30 minutes

const displayedNotifications = {};

export default function Header(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [profileMenu, setProfileMenu] = useState(null);
  const username = sessionStorage.getItem("email");
  const organization = sessionStorage.getItem("organization");
  const [dashboardName, setDashboardName] = useState("hydroEye");
  const [devicesHealth, setDevicesHealth] = useState({
    total: 0,
    active: 0,
    inactive: 0,
    alert: 0,
    unsubscribed: 0,
  });

  const dispatch = useDispatch();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  const store = useStore();
  const history = useHistory();

  const dashboardSettings = useSelector(
    (state) => state.configurationSlice.dashboardConfig
  );
  const orgBillingInfoStore = useSelector(
    (state) => state.billingInfoSlice.orgBillingInfo
  );
  const devicesHealthData = useSelector(
    (state) => state.devicesSlice.devicesHealth
  );
  //const defaultLogoImg = `${process.env.PUBLIC_URL}/sal-icon-grayscale.png`;
  const defaultLogoImg = `${process.env.PUBLIC_URL}/icon.png`;
  const [orgName, setOrgName] = useState("");
  const [logoImage, setlogoImage] = useState(defaultLogoImg);
  const [logoWidth, setlogoWidth] = useState(41);
  const [logoHeight, setlogoHeight] = useState(40);
  const siteDomain = sessionStorage.getItem("siteDomain");
  const isAdmin = sessionStorage.getItem("isAdmin") === "true";
  // const isNotificationShowed = sessionStorage.getItem("isNotificationShowed");

  const Logo = styled("img")({
    width: 130,
    // marginBottom: 8,
  });

  useEffect(() => {
    if (orgBillingInfoStore === null && organization) {
      dispatch(getOrgBillingInfo({ organization }));
    }
    // if (orgBillingInfoStore !== null && isNotificationShowed === "false") {
    //   if (orgBillingInfoStore.cancel_date && orgBillingInfoStore.cancel_date !== null) {
    //     const today = moment();
    //     const cancelDate = moment(orgBillingInfoStore.cancel_date);
    //     const daysPending = cancelDate.diff(today, 'days');
    //     if (daysPending < 0) {
    //       enqueueSnackbar('Cloud subscription has expired, Please renew your subscription to continue using our cloud services.', { variant: 'error' });
    //     } else if (daysPending < 3) {
    //       enqueueSnackbar(`Cloud subscription is expiring in ${daysPending} days, Please extend your subscription to continue using our cloud services.`, { variant: 'error' });
    //     } else if (daysPending < 15) {
    //       enqueueSnackbar(`Cloud subscription is expiring in ${daysPending} days, Please extend your subscription to continue using our cloud services.`, { variant: 'warning' });
    //     }
    //   }
    //   if (orgBillingInfoStore.wallet_balance !== undefined && orgBillingInfoStore.wallet_balance !== null) {
    //     let amount = (orgBillingInfoStore.wallet_balance > 0) ? `$${orgBillingInfoStore.wallet_balance}` : `-$${Math.abs(orgBillingInfoStore.wallet_balance)}`
    //     if (orgBillingInfoStore.wallet_balance < 1) {
    //       enqueueSnackbar(`Cloud wallet balance is very low (${amount}). Please add balance to your wallet!`, { variant: 'error' });
    //     } else if (orgBillingInfoStore.wallet_balance < 5) {
    //       enqueueSnackbar(`Cloud wallet balance is very low (${amount}). Please add balance to your wallet!`, { variant: 'warning' });
    //     }
    //   }
    //   sessionStorage.setItem('isNotificationShowed', 'true');
    // }
  }, [dispatch, organization, orgBillingInfoStore]);

  useEffect(() => {
    let healthData = store.getState().devicesSlice.devicesHealth;
    setDevicesHealth(healthData);
  }, [store]);

  useEffect(() => {
    if (dashboardSettings === null && organization) {
      let payloadUrl = `organizations/${organization}`;
      dispatch(getDashboardConfig({ url: payloadUrl }));
    }
    if (dashboardSettings !== null) {
      if (
        dashboardSettings.meta_data &&
        dashboardSettings.meta_data.custom_config
      ) {
        let config = dashboardSettings.meta_data.custom_config;
        sessionStorage.setItem("dashboard_config", JSON.stringify(config));
        if (typeof config.name === "object") {
          setDashboardName(config.name.value);
        } else if (config.name) {
          setDashboardName(config.name);
        }
      }
    }
  }, [dispatch, organization, dashboardSettings]);

  useEffect(() => {
    if (dashboardSettings !== null) {
      if (!!dashboardSettings.logo) {
        //setlogoImage(dashboardSettings.logo);
      } else if (
        whitelabelConfig[siteDomain] &&
        whitelabelConfig[siteDomain] !== undefined
      ) {
        setlogoImage(whitelabelConfig[siteDomain].logo);
        setlogoHeight(whitelabelConfig[siteDomain].logoHeight / 2);
        setlogoWidth(whitelabelConfig[siteDomain].logoWidth / 2);
      }
    }
  }, [dashboardSettings, siteDomain]);

  useEffect(() => {
    if (dashboardSettings !== null) {
      if (!!dashboardSettings.name) {
        setOrgName(`  ${dashboardSettings.name}`);
      }
    }
  }, [dashboardSettings]);

  // useEffect(() => {
  //   if (whitelabelConfig[siteDomain] && whitelabelConfig[siteDomain] !== undefined) {
  //     setlogoImage(whitelabelConfig[siteDomain].logo)
  //     setlogoHeight(whitelabelConfig[siteDomain].logoHeight / 2)
  //     setlogoWidth(whitelabelConfig[siteDomain].logoWidth / 2)
  //   } else {
  //     setlogoImage(`${process.env.PUBLIC_URL}/sal-icon-grayscale.png`)
  //     setlogoHeight(40)
  //     setlogoWidth(41)
  //   }
  // }, [siteDomain])

  useEffect(() => {
    if (devicesHealthData) {
      setDevicesHealth(devicesHealthData);
    }
  }, [dispatch, organization, devicesHealthData]);

  // const changePasswordHandler = () => {
  //   history.push({
  //     pathname: '/app/changePassword',
  //   });
  //   setProfileMenu(null);
  // };

  const accountSettingsHandler = () => {
    history.push({
      pathname: "/app/accountSettings",
    });
    setProfileMenu(null);
  };

  const userManagementHandler = () => {
    history.push({
      pathname: "/app/userManagement",
    });
    setProfileMenu(null);
  };

  const performSignOut = useCallback(() => {
    dispatch({ type: APIStatus.closeSession });
    signOut(userDispatch, props.history);
  }, [dispatch, props.history, userDispatch]);

  const updateRefreshToken = useCallback(() => {
    const refreshPayload = {
      token: sessionStorage.getItem("id_token"),
    };

    axios
      .post("/refresh-jwt-token", refreshPayload)
      .then((res) => {
        if (res && res.status && res.status === 200) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `JWT ${res.data.token}`;
          sessionStorage.setItem("id_token", res.data.token);
        } else {
          performSignOut();
        }
        console.log("TEst");
      })
      .catch((err) => {
        performSignOut();
      });
  }, [performSignOut]);

  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err?.response?.status === 401) {
        updateRefreshToken();
      }
      if (
        err?.response?.status === 403 &&
        err?.response?.data?.detail !==
          "Unable to log in with provided credentials."
      ) {
        const errorMessage =
          "You do not have permission to perform this action";

        // Check if this notification is already displayed
        if (!displayedNotifications[errorMessage]) {
          enqueueSnackbar(errorMessage, { variant: "error" });

          // Add the message to the displayed notifications
          Object.assign(displayedNotifications, {
            [errorMessage]: errorMessage,
          });

          // Optionally, remove the message after a certain time, e.g., 5 seconds
          setTimeout(() => {
            delete displayedNotifications[errorMessage];
          }, 5000);
        }
      }
      return Promise.reject(err);
    }
  );

  useEffect(() => {
    const token = sessionStorage.getItem("id_token");
    if (token) {
      // Keep checking after a certain time
      const intervalId = setInterval(() => {
        updateRefreshToken();
      }, ACCESS_TOKEN_EXPIRES_TIME);
      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [updateRefreshToken]);

  return (
    <AppBar sx={{ background: 'linear-gradient(177deg,rgba(25,25,25,1), rgba(0,25,113, 1))' }} position="fixed">
      <Toolbar className={classes.toolbar}>
        <IconButton
          size="large"
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", placeItems: "center", gap: 10 }}>
            <Box display="flex" alignItems="center" ml={2}>
              <img
                alt="icon"
                src={logoImage}
                width={logoWidth}
                height={logoHeight}
                style={{ height: 40, width: "unset" }}
              />
            </Box>
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: 15,
                fontWeight: "normal",
              }}
            >
              {orgName}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h5" weight="bold" className={classes.logotype}>
              {dashboardName}
            </Typography>
            <div style={{ fontSize: "12px" }}>
              Powered by{" "}
              <a
                style={{
                  textDecoration: "none",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                href="https://www.spaceage-labs.com/"
              >
                SpaceAge Labs
              </a>
            </div>
          </div>
          <div style={{ display: "flex", placeItems: "center", gap: 10 }}>
            <div style={{ display: "flex", width: 150 }}>
              {devicesHealth !== null && (
                <>
                  <Tooltip title="No. of Active Devices" placement="bottom">
                    <div
                      className={classes.deviceCount}
                      style={{ backgroundColor: "#00ffeb", color: "#000000" }}
                    >
                      {devicesHealth.active}
                    </div>
                  </Tooltip>
                  <Tooltip title="No. of Alert Devices" placement="bottom">
                    <div
                      className={classes.deviceCount}
                      style={{ backgroundColor: "#F55e84" }}
                    >
                      {devicesHealth.alert}
                    </div>
                  </Tooltip>
                  <Tooltip title="No. of InActive Devices" placement="bottom">
                    <div
                      className={classes.deviceCount}
                      style={{ backgroundColor: "#DFE1E6" }}
                    >
                      {devicesHealth.inactive}
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
            <IconButton
              size="large"
              aria-haspopup="true"
              color="inherit"
              style={{ marginLeft: 0 }}
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <AccountIcon classes={{ root: classes.headerIcon }} />
            </IconButton>
          </div>
        </div>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography style={{ fontSize: 16 }} weight="medium">
              {username}
            </Typography>
          </div>
          <div className={classes.profileMenuUser}>
            <div className={classes.profileMenuLinkWrapper}>
              {/* <Typography
                style={{ marginRight: '10px', fontSize: 14, color: '#2d4d76', fontWeight: 'bold'  }}
                className={classes.profileMenuLink}
                color="primary"
                weight="bold"
                onClick={()=>changePasswordHandler()}
              >
                Change Password
              </Typography> */}
              <Typography
                style={{
                  marginRight: "10px",
                  fontSize: 14,
                  color: "#2d4d76",
                  fontWeight: "bold",
                }}
                className={classes.profileMenuLink}
                color="primary"
                weight="bold"
                onClick={() => accountSettingsHandler()}
              >
                Account Settings
              </Typography>
              {isAdmin && (
                <Typography
                  style={{
                    marginRight: "10px",
                    fontSize: 14,
                    color: "#2d4d76",
                    fontWeight: "bold",
                  }}
                  className={classes.profileMenuLink}
                  color="primary"
                  weight="bold"
                  onClick={() => userManagementHandler()}
                >
                  User Management
                </Typography>
              )}
              <Typography
                className={classes.profileMenuLink}
                weight="bold"
                color="primary"
                onClick={performSignOut}
              >
                Sign Out
              </Typography>
            </div>
          </div>
        </Menu>
      </Toolbar>
      <RebrandingBanner className="orgBanner" />
    </AppBar>
  );
}
