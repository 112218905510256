import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

import { APIStatus } from "./APIStatus";
import { extractCountAndUnit } from "../util/util";

const initialState = {
  customDashboardsStatus: APIStatus.idle,
  customDashboards: null,
  customDashboardsError: null,
  orgFeedsStatus: APIStatus.idle,
  orgFeeds: null,
  orgFeedsError: null,
  widgetDataStatus: APIStatus.idle,
  widgetData: null,
  widgetDataError: null,
  previewWidgetDataStatus: APIStatus.idle,
  previewWidgetData: null,
  previewWidgetDataError: null,
  widgetWeatherDataStatus: APIStatus.idle,
  widgetWeatherData: null,
  widgetWeatherDataError: null,
  addCustomDashboardStatus: APIStatus.idle,
  addCustomDashboardReceipt: null,
  addCustomDashboardError: null,
  updateCustomDashboardStatus: APIStatus.idle,
  updateCustomDashboardReceipt: null,
  updateCustomDashboardError: null,
  updateLayoutStatus: APIStatus.idle,
  updateLayoutReceipt: null,
  updateLayoutError: null,
  widgetsStatus: APIStatus.idle,
  widgets: null,
  widgetsError: null,
  addWidgetStatus: APIStatus.idle,
  addWidgetReceipt: null,
  addWidgetError: null,
  updateWidgetStatus: APIStatus.idle,
  updateWidgetReceipt: null,
  updateWidgetError: null,
  deleteWidgetStatus: APIStatus.idle,
  deleteWidgetReceipt: null,
  deleteWidgetError: null,
  deleteCustomDashboardStatus: APIStatus.idle,
  deleteCustomDashboardReceipt: null,
  deleteCustomDashboardError: null,
};

function getWidgetTimeRange(widget) {
  let startTime = "";
  let endTime = "";
  const widgetTimeFormat = "YYYY-MM-DD HH:mm:ss";
  const v2Info = widget.data?.v2;

  if (v2Info) {
    const timeRange = extractCountAndUnit(v2Info.scaleValue.value);
    if (timeRange) {
      startTime = moment()
        .subtract(timeRange.amount, timeRange.unit)
        .format(widgetTimeFormat)
        .valueOf();
      endTime = moment().format(widgetTimeFormat).valueOf();
    }
  } else {
    if (widget.data.scaleValue) {
      if (widget.data.scaleValue === "latest") {
      } else if (widget.data.scaleValue === "timeperiod") {
        startTime = moment(widget.data.startTime).format(widgetTimeFormat);
        endTime = moment(widget.data.endTime).format(widgetTimeFormat);
      } else if (widget.data.scaleValue === "timeperiodSingle") {
        startTime = moment(widget.data.startTime).format(widgetTimeFormat);
        endTime = moment(widget.data.endTime).format(widgetTimeFormat);
      } else if (
        widget.data.scaleValue === "timeperiodWeek" ||
        widget.data.scaleValue === "timeperiodMonth"
      ) {
        startTime = moment(widget.data.startTime).format(widgetTimeFormat);
        endTime = moment(widget.data.endTime).format(widgetTimeFormat);
      } else {
        let time = moment.duration(widget.data.scaleValue);
        startTime = moment().subtract(time).format(widgetTimeFormat).valueOf();
        endTime = moment().format(widgetTimeFormat).valueOf();
      }
    }
  }
  return { startTime, endTime };
}

export const getcustomDashboards = createAsyncThunk(
  "getcustomDashboards",
  async (payload) => {
    const response = await axios.get(`/${payload.organization}/dashboards`);
    return response;
  }
);

export const getOrgFeeds = createAsyncThunk("getOrgFeeds", async (payload) => {
  const response = await axios.get(payload.url);
  const { results, count } = response.data;

  if (count <= 100) {
    return { data: { results } };
  }
  const requests = [];
  for (let j = 1; j < count / 100; j++) {
    requests.push(axios.get(`${payload.url}&offset=${j * 100}`));
  }

  const dataArr = await Promise.all(requests).then((responses) =>
    responses.reduce((acc, res) => acc.concat(res.data.results), results)
  );

  return { data: { results: dataArr } };
});

export const fetchWidgetDataV2 = createAsyncThunk(
  "fetchWidgetDataV2",
  async (
    { organization, widget, orgFeeds, frequency = 1 },
    { rejectWithValue }
  ) => {
    try {
      const widgetTimeRange = getWidgetTimeRange(widget);
      const filteredFeeds = [];
      const dataSourceRequests = [];
      const feedRuleRequests = [];
      let statAPIType = "data-stat";
      const v2InfoExists = widget.data.v2 || false;

      for (const feedKey of widget.data.datasource) {
        const feed = orgFeeds.find((feed) => feed.key === feedKey);
        if (!feed) continue;

        if (v2InfoExists) {
          const dataSourceFields = v2InfoExists.dataSourceFields;
          const dataSourceField = dataSourceFields.find(
            (dataSourceField) =>
              (dataSourceField.feed || dataSourceField.feeds[0]) === feedKey
          );
          if (dataSourceField) {
            statAPIType = dataSourceField.statAPIType;
          }
        }

        filteredFeeds.push(feed);
        dataSourceRequests.push(
          axios.get(
            `/${organization}/feeds/${feedKey}/${statAPIType}/${frequency}?completed_at__range=${widgetTimeRange.startTime},${widgetTimeRange.endTime}`
          )
        );
        feedRuleRequests.push(
          axios.get(`/${organization}/feeds/rules/?feed=${feed.id}`)
        );
      }

      if (!filteredFeeds.length) {
        return rejectWithValue("No valid feeds found");
      }

      const dataSourceResponses = await Promise.all(dataSourceRequests);
      const feedRulesResponses = await Promise.all(feedRuleRequests);

      const feedData = [];
      let feedRules = [];
      for (let i = 0; i < dataSourceResponses.length; i += 1) {
        feedData.push(dataSourceResponses[i].data);
      }
      for (let i = 0; i < feedRulesResponses.length; i += 1) {
        feedRules.push(feedRulesResponses[i].data.results);
      }

      return {
        feeds: filteredFeeds,
        feedData,
        feedRules,
      };
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.detail || "Failed to fetch widget data"
      );
    }
  }
);

export const getWidgetDataV2 = createAsyncThunk(
  "getLineWidgetData",
  async (
    { organization, widget, orgFeeds, frequency = 1, preventRuleAPI = false },
    { rejectWithValue, getState }
  ) => {
    try {
      const widgetTimeRange = getWidgetTimeRange(widget);
      const filteredFeeds = [];
      const dataSourceRequests = [];
      const feedRuleRequests = [];
      let statAPIType = "data-stat";
      const v2InfoExists = widget.data.v2 || false;

      for (const feedKey of widget.data.datasource) {
        const feed = orgFeeds.find((feed) => feed.key === feedKey);
        if (!feed) continue;

        if (v2InfoExists) {
          const dataSourceFields = v2InfoExists.dataSourceFields;
          const dataSourceField = dataSourceFields.find(
            (dataSourceField) =>
              (dataSourceField.feed || dataSourceField.feeds[0]) === feedKey
          );
          if (dataSourceField) {
            statAPIType = dataSourceField.statAPIType;
          }
        }

        filteredFeeds.push(feed);
        dataSourceRequests.push(
          axios.get(
            `/${organization}/feeds/${feedKey}/${statAPIType}/${frequency}?completed_at__range=${widgetTimeRange.startTime},${widgetTimeRange.endTime}`
          )
        );
        if (!preventRuleAPI) {
          feedRuleRequests.push(
            axios.get(`/${organization}/feeds/rules/?feed=${feed.id}`)
          );
        }
      }

      if (!filteredFeeds.length) {
        return rejectWithValue("No valid feeds found");
      }

      const dataSourceResponses = await Promise.all(dataSourceRequests);
      let feedRulesResponses;
      if (!preventRuleAPI) {
        feedRulesResponses = await Promise.all(feedRuleRequests);
      }

      const feedData = [];
      let feedRules = [];
      for (let i = 0; i < dataSourceResponses.length; i += 1) {
        feedData.push(dataSourceResponses[i].data);
      }
      if (!preventRuleAPI) {
        for (let i = 0; i < feedRulesResponses.length; i += 1) {
          feedRules.push(feedRulesResponses[i].data.results);
        }
      } else {
        const state = getState();
        const widgetData = state.customDashboards.widgetData[widget.key];
        feedRules = widgetData.feedRules;
      }

      return {
        [widget.key]: {
          feeds: filteredFeeds,
          feedData,
          feedRules,
        },
      };
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.detail || "Failed to fetch widget data"
      );
    }
  }
);

export const getLineWidgetData = createAsyncThunk(
  "getLineWidgetData",
  async (
    { organization, widget, orgFeeds, frequency = 1 },
    { rejectWithValue }
  ) => {
    try {
      const widgetTimeRange = getWidgetTimeRange(widget);
      const filteredFeeds = [];
      const requests = [];

      for (const feedKey of widget.data.datasource) {
        const feed = orgFeeds.find((feed) => feed.key === feedKey);
        if (!feed) continue;

        filteredFeeds.push(feed);

        const apiEndPoint =
          widgetTimeRange?.startTime && widgetTimeRange?.endTime
            ? `/${organization}/feeds/${feedKey}/data-stat/${frequency}?completed_at__range=${widgetTimeRange.startTime},${widgetTimeRange.endTime}`
            : `/${organization}/feeds/${feedKey}/data?limit=25`;

        requests.push(
          axios.get(apiEndPoint),
          axios.get(`/${organization}/feeds/rules/?feed=${feed.id}`)
        );
      }

      if (!filteredFeeds.length) {
        return rejectWithValue("No valid feeds found");
      }

      const responses = await Promise.all(requests);

      const feedData = [];
      const feedRules = [];
      for (let i = 0; i < responses.length; i += 2) {
        feedData.push(responses[i].data);
        feedRules.push(responses[i + 1].data);
      }

      return {
        [widget.key]: {
          feeds: filteredFeeds,
          feedData,
          feedRules,
        },
      };
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.detail || "Failed to fetch widget data"
      );
    }
  }
);

export const getDeviceCountWidgetData = createAsyncThunk(
  "getDeviceCountWidgetData",
  async ({ organization, widget, deviceId, startTime, endTime }) => {
    const response = await axios.get(
      `/${organization}/devices/${deviceId}/statistics/${startTime}/${endTime}`
    );
    let data = {
      [widget.key]: {
        deviceCount: response.data,
      },
    };
    return data;
  }
);

export const getDeviceFeedsWidgetData = createAsyncThunk(
  "getDeviceFeedsWidgetData",
  async ({ organization, widget, deviceId }) => {
    const response = await axios.get(
      `/${organization}/feeds?device__device_id=${deviceId}`
    );
    let data = {
      [widget.key]: {
        deviceFeeds: response.data,
      },
    };
    return data;
  }
);

export const getFeedCountWidgetData = createAsyncThunk(
  "getFeedCountWidgetData",
  async ({ organization, widget, feedKey, startTime, endTime }) => {
    const response = await axios.get(
      `/${organization}/feeds/${feedKey}/statistics/${startTime}/${endTime} `
    );
    let data = {
      [widget.key]: {
        feedCount: response.data,
      },
    };
    return data;
  }
);

export const getFeedWidgetData = createAsyncThunk(
  "getFeedWidgetData",
  async ({ organization, widget, feedKey, startTime, endTime }) => {
    let apiEndPoint = "";
    if (startTime && endTime) {
      apiEndPoint = `/${organization}/feeds/${feedKey}/data-stat/1?completed_at__range=${startTime},${endTime}`;
    } else {
      apiEndPoint = `/${organization}/feeds/${feedKey}/data?limit=25`;
    }
    const response = await axios.get(apiEndPoint);
    let data = {
      [widget.key]: {
        feedValues: response.data,
      },
    };
    return data;
  }
);

export const getTableWidgetData = createAsyncThunk(
  "getTableWidgetData",
  async ({ organization, widget, orgFeeds, frequency = 1 }) => {
    let widgetTimeRange = getWidgetTimeRange(widget);
    let reqDataArr = [];
    let filteredFeeds = [];
    for (var f = 0; f < widget.data.datasource.length; f++) {
      let feedKey = widget.data.datasource[f];
      let feed = orgFeeds.find((feed) => feed.key === feedKey);
      if (feed) {
        filteredFeeds.push(feed);
        let apiEndPoint = `/${organization}/feeds/${feedKey}/data?limit=25`;
        if (
          widgetTimeRange &&
          widgetTimeRange.startTime &&
          widgetTimeRange.endTime
        ) {
          apiEndPoint = `/${organization}/feeds/${feedKey}/data-stat/${frequency}?completed_at__range=${widgetTimeRange.startTime},${widgetTimeRange.endTime}`;
        }
        reqDataArr.push(axios.get(apiEndPoint).then((resp) => resp.data));
      }
    }
    const responses = await Promise.all(reqDataArr);

    let data = {
      [widget.key]: {
        feeds: filteredFeeds,
        feedData: responses.slice(0, filteredFeeds.length),
      },
    };
    return data;
  }
);

export const getweatherData = createAsyncThunk(
  "getweatherData",
  async (payload) => {
    const timeDiff = moment(payload.endDate).diff(
      moment(payload.startDate),
      "days"
    );
    let rainfallDataArr = [];
    if (timeDiff > 30) {
      let noOfCalls = parseInt(timeDiff / 30 + 1);
      let startDate = moment(payload.startDate).format("DD-MM-YYYY");
      let currentDate = moment().format("DD-MM-YYYY");
      for (let i = 0; i < noOfCalls; i++) {
        let endDate = moment(startDate, "DD-MM-YYYY")
          .add(30, "days")
          .format("DD-MM-YYYY");
        if (i === noOfCalls - 1) {
          if (moment(currentDate).isSame(moment(endDate)) === false) {
            endDate = moment().format("DD-MM-YYYY");
          }
        }
        rainfallDataArr.push(
          axios.get(
            `/premium/v1/past-weather.ashx?key=guv5e6mryzrefewuy4444vc5&q=${payload.postCode}&format=json&date=${startDate}&enddate=${endDate}&tp=1`,
            { baseURL: "https://api.worldweatheronline.com" }
          )
        );
        startDate = moment(startDate, "DD-MM-YYYY")
          .add(30, "days")
          .format("DD-MM-YYYY");
      }
    } else {
      rainfallDataArr.push(
        axios.get(
          `/premium/v1/past-weather.ashx?key=guv5e6mryzrefewuy4444vc5&q=${payload.postCode}&format=json&date=${payload.startDate}&enddate=${payload.endDate}&tp=1`,
          { baseURL: "https://api.worldweatheronline.com" }
        )
      );
    }
    const response = axios.all(rainfallDataArr).then(
      axios.spread((...responses) => {
        let rainFallData = [];
        let responseData = {};
        for (let r = 0; r < responses.length; r++) {
          let res = responses[r];
          res.data.data.weather.forEach((w) => {
            if (w.hourly && w.hourly.length > 0) {
              let date = w.date;
              w.hourly.forEach((wD) => {
                let time = moment(wD.time, "Hmm").format("HH:mm");
                if (time === "Invalid date") {
                  time = moment(wD.time, "HHmm").format("HH:mm");
                }
                let value = wD.precipMM;
                let timeStamp = date + time;
                let dateTime = moment(timeStamp, "YYYY-MM-DDHHmm").format();
                timeStamp = moment(timeStamp, "YYYY-MM-DDHHmm").unix();
                rainFallData.push({ timeStamp, value, dateTime });
              });
            }
          });
        }
        responseData = {
          data: {
            widgetKey: payload.widgetKey,
            weather: rainFallData,
          },
        };
        return responseData;
      })
    );
    return response;
  }
);

export const getRainFallData = createAsyncThunk(
  "getRainFallData",
  async (payload) => {
    const {
      widgetKey,
      organization,
      feedKey,
      startTime,
      endTime,
      frequency = 60,
    } = payload;
    const response = await axios
      .get(
        `/${organization}/feeds/${feedKey}/data-stat/${frequency}?completed_at__range=${startTime},${endTime}`
      )
      .then(({ data }) => {
        const rainFallData = data.map((feedData) => {
          const value = feedData[1];
          let timeStamp = feedData[0];
          const dateTime = moment(timeStamp, "YYYY-MM-DDHHmm").format();
          timeStamp = moment(timeStamp, "YYYY-MM-DDHHmm").unix();
          return { timeStamp, value, dateTime };
        });
        return rainFallData;
      })
      .catch((err) => {
        return [];
      });
    const responseData = {
      data: {
        widgetKey,
        weather: response,
      },
    };
    return responseData;
  }
);

export const addCustomDashboard = createAsyncThunk(
  "addcustomDashboard",
  async (payload) => {
    const response = await axios.post(
      `/${payload.organization}/dashboards`,
      payload.data
    );
    return response;
  }
);

export const getWidgets = createAsyncThunk(
  "getWidgets",
  async ({ organization, dashboardKey }) => {
    const response = await axios.get(
      `/${organization}/dashboards/${dashboardKey}`
    );
    return response;
  }
);

export const addWidget = createAsyncThunk(
  "addWidget",
  async ({ organization, dashboardKey, data }) => {
    const response = await axios.post(
      `/${organization}/dashboards/${dashboardKey}/widgets`,
      data
    );
    return response;
  }
);

export const updateWidget = createAsyncThunk(
  "updateWidget",
  async ({ organization, dashboardKey, widgetId, data }) => {
    const response = await axios.patch(
      `/${organization}/dashboards/${dashboardKey}/widgets/${widgetId}`,
      data
    );
    return response;
  }
);

export const deleteWidget = createAsyncThunk(
  "deleteWidget",
  async ({ organization, dashboardKey, widgetId }) => {
    const response = await axios.delete(
      `/${organization}/dashboards/${dashboardKey}/widgets/${widgetId}`
    );
    return response;
  }
);

export const updateCustomDashboard = createAsyncThunk(
  "updateCustomDashboard",
  async ({ organization, dashboardKey, data }) => {
    const response = await axios.patch(
      `/${organization}/dashboards/${dashboardKey}`,
      data
    );
    return response;
  }
);

export const deleteCustomDashboard = createAsyncThunk(
  "deleteCustomDashboard",
  async ({ organization, dashboardKey }, { rejectWithValue }) => {
    return axios
      .delete(`/${organization}/dashboards/${dashboardKey}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(
          err?.response?.data?.detail || "Failed to Delete Dashboard"
        );
      });
  }
);

const customDashboardsSlice = createSlice({
  name: "customDashboards",
  initialState,
  reducers: {
    reset(state, action) {
      state = { ...initialState };
    },
    resetPreviewWidgetData(state, action) {
      state.previewWidgetData = null;
    },
    clearWidgetData(state, action) {
      state.widgetDataStatus = APIStatus.idle;
      state.widgetData = {};
      state.widgetWeatherData = {};
      state.widgetDataError = null;
    },
    resetAfterAddDashboard(state, action) {
      state.addCustomDashboardStatus = APIStatus.idle;
      state.addCustomDashboardReceipt = null;
      state.addCustomDashboardError = null;
    },
    resetAfterUpdateDashboard(state, action) {
      state.updateCustomDashboardStatus = APIStatus.idle;
      state.updateCustomDashboardReceipt = null;
      state.updateCustomDashboardError = null;
    },
    resetAfterDeleteDashboard(state, action) {
      state.deleteCustomDashboardStatus = APIStatus.idle;
      state.deleteCustomDashboardReceipt = null;
      state.deleteCustomDashboardError = null;
    },
    clearAddWidget(state, action) {
      state.addWidgetStatus = APIStatus.idle;
      state.addWidgetReceipt = null;
      state.addWidgetError = null;
    },
    clearUpdateWidget(state, action) {
      state.updateWidgetStatus = APIStatus.idle;
      state.updateWidgetReceipt = null;
      state.updateWidgetError = null;
    },
    clearDeleteWidget(state, action) {
      state.deleteWidgetStatus = APIStatus.idle;
      state.deleteWidgetReceipt = null;
      state.deleteWidgetError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getcustomDashboards.pending, (state, action) => {
        state.customDashboardsStatus = APIStatus.loading;
      })
      .addCase(getcustomDashboards.fulfilled, (state, action) => {
        state.customDashboardsStatus = APIStatus.loaded;
        state.customDashboards = action.payload
          ? action.payload.data.results
          : null;
      })
      .addCase(getcustomDashboards.rejected, (state, action) => {
        state.customDashboardsStatus = APIStatus.failed;
        state.customDashboardsError = action.error;
      })
      .addCase(getOrgFeeds.pending, (state, action) => {
        state.orgFeedsStatus = APIStatus.loading;
      })
      .addCase(getOrgFeeds.fulfilled, (state, action) => {
        state.orgFeedsStatus = APIStatus.loaded;
        state.orgFeeds = action.payload ? action.payload.data.results : null;
      })
      .addCase(getOrgFeeds.rejected, (state, action) => {
        state.orgFeedsStatus = APIStatus.failed;
        state.orgFeedsError = action.error;
      })
      .addCase(getLineWidgetData.pending, (state, action) => {
        state.widgetDataStatus = APIStatus.loading;
      })
      .addCase(getLineWidgetData.fulfilled, (state, action) => {
        state.widgetDataStatus = APIStatus.loaded;
        state.widgetData = {
          ...state.widgetData,
          ...action.payload,
        };
      })
      .addCase(getLineWidgetData.rejected, (state, action) => {
        state.widgetDataStatus = APIStatus.failed;
        state.widgetDataError = action.error;
      })
      .addCase(fetchWidgetDataV2.pending, (state, action) => {
        state.previewWidgetDataStatus = APIStatus.loading;
      })
      .addCase(fetchWidgetDataV2.fulfilled, (state, action) => {
        state.previewWidgetDataStatus = APIStatus.loaded;
        state.previewWidgetData = {
          ...action.payload,
        };
      })
      .addCase(fetchWidgetDataV2.rejected, (state, action) => {
        state.previewWidgetDataStatus = APIStatus.failed;
        state.previewWidgetDataError = action.error;
      })
      .addCase(getDeviceCountWidgetData.pending, (state, action) => {
        state.widgetDataStatus = APIStatus.loading;
      })
      .addCase(getDeviceCountWidgetData.fulfilled, (state, action) => {
        state.widgetDataStatus = APIStatus.loaded;
        state.widgetData = {
          ...state.widgetData,
          ...action.payload,
        };
      })
      .addCase(getDeviceCountWidgetData.rejected, (state, action) => {
        state.widgetDataStatus = APIStatus.failed;
        state.widgetDataError = action.error;
      })
      .addCase(getDeviceFeedsWidgetData.pending, (state, action) => {
        state.widgetDataStatus = APIStatus.loading;
      })
      .addCase(getDeviceFeedsWidgetData.fulfilled, (state, action) => {
        state.widgetDataStatus = APIStatus.loaded;
        state.widgetData = {
          ...state.widgetData,
          ...action.payload,
        };
      })
      .addCase(getDeviceFeedsWidgetData.rejected, (state, action) => {
        state.widgetDataStatus = APIStatus.failed;
        state.widgetDataError = action.error;
      })
      .addCase(getFeedCountWidgetData.pending, (state, action) => {
        state.widgetDataStatus = APIStatus.loading;
      })
      .addCase(getFeedCountWidgetData.fulfilled, (state, action) => {
        state.widgetDataStatus = APIStatus.loaded;
        state.widgetData = {
          ...state.widgetData,
          ...action.payload,
        };
      })
      .addCase(getFeedCountWidgetData.rejected, (state, action) => {
        state.widgetDataStatus = APIStatus.failed;
        state.widgetDataError = action.error;
      })
      .addCase(getFeedWidgetData.pending, (state, action) => {
        state.widgetDataStatus = APIStatus.loading;
      })
      .addCase(getFeedWidgetData.fulfilled, (state, action) => {
        state.widgetDataStatus = APIStatus.loaded;
        state.widgetData = {
          ...state.widgetData,
          ...action.payload,
        };
      })
      .addCase(getFeedWidgetData.rejected, (state, action) => {
        state.widgetDataStatus = APIStatus.failed;
        state.widgetDataError = action.error;
      })
      .addCase(getweatherData.pending, (state, action) => {
        state.widgetWeatherDataStatus = APIStatus.loading;
      })
      .addCase(getweatherData.fulfilled, (state, action) => {
        state.widgetWeatherDataStatus = APIStatus.loaded;
        state.widgetWeatherData = {
          ...state.widgetWeatherData,
          [action.payload.data.widgetKey]: {
            weather: action.payload.data.weather,
          },
        };
      })
      .addCase(getweatherData.rejected, (state, action) => {
        state.widgetWeatherDataStatus = APIStatus.failed;
        state.widgetWeatherDataError = action.error;
      })
      .addCase(getRainFallData.pending, (state, action) => {
        state.widgetWeatherDataStatus = APIStatus.loading;
      })
      .addCase(getRainFallData.fulfilled, (state, action) => {
        state.widgetWeatherDataStatus = APIStatus.loaded;
        state.widgetWeatherData = {
          ...state.widgetWeatherData,
          [action.payload.data.widgetKey]: {
            weather: action.payload.data.weather,
          },
        };
      })
      .addCase(getRainFallData.rejected, (state, action) => {
        state.widgetWeatherDataStatus = APIStatus.failed;
        state.widgetWeatherDataError = action.error;
      })
      .addCase(getTableWidgetData.pending, (state, action) => {
        state.widgetDataStatus = APIStatus.loading;
      })
      .addCase(getTableWidgetData.fulfilled, (state, action) => {
        state.widgetDataStatus = APIStatus.loaded;
        state.widgetData = {
          ...state.widgetData,
          ...action.payload,
        };
      })
      .addCase(getTableWidgetData.rejected, (state, action) => {
        state.widgetDataStatus = APIStatus.failed;
        state.widgetDataError = action.error;
      })
      .addCase(addCustomDashboard.pending, (state, action) => {
        state.addCustomDashboardStatus = APIStatus.loading;
      })
      .addCase(addCustomDashboard.fulfilled, (state, action) => {
        state.addCustomDashboardStatus = APIStatus.loaded;
        state.addCustomDashboardReceipt = action.payload
          ? action.payload.data
          : null;
      })
      .addCase(addCustomDashboard.rejected, (state, action) => {
        state.addCustomDashboardStatus = APIStatus.failed;
        state.addCustomDashboardError = action.error;
      })
      .addCase(getWidgets.pending, (state, action) => {
        state.widgetsStatus = APIStatus.loading;
      })
      .addCase(getWidgets.fulfilled, (state, action) => {
        state.widgetsStatus = APIStatus.loaded;
        state.widgets = action.payload ? action.payload.data.results : null;
      })
      .addCase(getWidgets.rejected, (state, action) => {
        state.widgetsStatus = APIStatus.failed;
        state.widgetsError = action.error;
      })
      .addCase(addWidget.pending, (state, action) => {
        state.addWidgetStatus = APIStatus.loading;
      })
      .addCase(addWidget.fulfilled, (state, action) => {
        state.addWidgetStatus = APIStatus.loaded;
        state.addWidgetReceipt = action.payload ? action.payload.data : null;
      })
      .addCase(addWidget.rejected, (state, action) => {
        state.addWidgetStatus = APIStatus.failed;
        state.addWidgetError = action.error;
      })
      .addCase(updateWidget.pending, (state, action) => {
        state.updateWidgetsStatus = APIStatus.loading;
      })
      .addCase(updateWidget.fulfilled, (state, action) => {
        state.updateWidgetStatus = APIStatus.loaded;
        state.updateWidgetReceipt = action.payload ? action.payload.data : null;

        if (action.meta.arg.widgetKey) {
          state.widgetData[action.meta.arg.widgetKey] = null;
          if (!state.widgetWeatherData) {
            state.widgetWeatherData = {};
          }
        }
      })
      .addCase(updateWidget.rejected, (state, action) => {
        state.updateWidgetStatus = APIStatus.failed;
        state.updateWidgetError = action.error;
      })
      .addCase(deleteWidget.pending, (state, action) => {
        state.deleteWidgetStatus = APIStatus.loading;
      })
      .addCase(deleteWidget.fulfilled, (state, action) => {
        state.deleteWidgetStatus = APIStatus.loaded;
        state.deleteWidgetReceipt = action.payload ? action.payload.data : null;
      })
      .addCase(deleteWidget.rejected, (state, action) => {
        state.deleteWidgetStatus = APIStatus.failed;
        state.deleteWidgetError = action.error;
      })
      .addCase(updateCustomDashboard.pending, (state, action) => {
        // if (action.meta.arg.action === 'updateLayout') {
        //     state.updateLayoutStatus = APIStatus.loading;
        // } else {
        state.updateCustomDashboardStatus = APIStatus.loading;
        // }
      })
      .addCase(updateCustomDashboard.fulfilled, (state, action) => {
        // if (action.meta.arg.action === 'updateLayout') {
        //     state.updateLayoutStatus = APIStatus.loaded;
        //     state.updateLayoutReceipt = action.payload ? action.payload.data :  null;
        // } else {
        state.updateCustomDashboardStatus = APIStatus.loaded;
        state.updateCustomDashboardReceipt = {
          ...action.payload.data,
          mode: action.meta.arg.mode,
        };
        // }
      })
      .addCase(updateCustomDashboard.rejected, (state, action) => {
        // if (action.meta.arg.action === 'updateLayout') {
        //     state.updateLayoutStatus = APIStatus.failed;
        //     state.updateLayoutError = action.error;
        // } else {
        state.updateCustomDashboardStatus = APIStatus.failed;
        state.updateCustomDashboardError = {
          ...action.error,
          mode: action.meta.arg.mode,
        };
        // }
      })
      .addCase(deleteCustomDashboard.pending, (state, action) => {
        state.deleteCustomDashboardStatus = APIStatus.loading;
      })
      .addCase(deleteCustomDashboard.fulfilled, (state, action) => {
        state.deleteCustomDashboardStatus = APIStatus.loaded;
        state.deleteCustomDashboardReceipt = action.payload
          ? action.payload.data.results
          : null;
      })
      .addCase(deleteCustomDashboard.rejected, (state, action) => {
        state.deleteCustomDashboardStatus = APIStatus.failed;
        state.deleteCustomDashboardError = action.error;
      });
  },
});

export const {
  reset,
  resetPreviewWidgetData,
  clearWidgetData,
  resetAfterAddDashboard,
  clearAddWidget,
  clearUpdateWidget,
  resetAfterUpdateDashboard,
  clearDeleteWidget,
  resetAfterDeleteDashboard,
} = customDashboardsSlice.actions;

export default customDashboardsSlice.reducer;
