import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Link
} from "@mui/material";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  AccountCircle,
} from "@mui/icons-material";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import axios from 'axios';
import moment from "moment";
//import MaterialTable from 'material-table';
import { useParams, useHistory } from "react-router-dom";
import RebrandingBanner from "../RebrandingBanner/RebrandingBanner";


function createAudit(keyId,user, eventType, content, object, logTime) {
  if (eventType == 'updated' && content == 'user') {
    return { id : keyId, type : "notification", color:"secondary",  message: user + ' ' + 'logged in at ' + moment(logTime).format('DD-MMM-YYYY hh:mm A') };
  }
  else if(eventType == 'many-to-many changed'){
    return { id : keyId, type : "notification", color:"secondary",  message: user + ' ' + 'updated ' + content + ' ' + object + ' at ' + moment(logTime).format('DD-MMM-YYYY hh:mm A') };
  }
  else {
    return { id : keyId, type : "notification", color:"secondary",  message:user + ' ' + eventType + ' ' + content + ' ' + object + ' at ' + moment(logTime).format('DD-MMM-YYYY hh:mm A') };
  }
}
function createuserData(firstName, lastName, email, role) {
  return { firstName: firstName, lastName: lastName, user: email, role: role };
}
export default function EnterpriseHeader(props) {
  var classes = useStyles();
  const history = useHistory();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  const [isSalAdmin, setisSalAdmin] = React.useState("")
  const [auditMsg, setauditMsg] = React.useState([])
  const [isSalReadonly, setiisSalReadonly] = React.useState("")
  const [enterpriseName, setenterpriseName] = React.useState("")
  useEffect(() => {
    var adminState = (`${sessionStorage.getItem("isSalAdmin")}` == 'true')
    setisSalAdmin(adminState)
    var salReadOnly = (`${sessionStorage.getItem("isReadOnly")}` == 'true')
    setiisSalReadonly(salReadOnly)
    var readOnlyState = `${sessionStorage.getItem("isReadOnly")}`

    axios.get(`/${sessionStorage.getItem("organization")}/enterprise-org/${sessionStorage.getItem("organization")}`).then(res => {
      let userData = res.data.name;
      if(userData)
      {
      setenterpriseName('Enterprise Dashboard - ' + userData)     
      }
      else{
        setenterpriseName('Enterprise Dashboard')
      }
      }).catch(err => {
          console.log(err);
      });
    if(readOnlyState == 'false')
    {
     axios.get(`/${sessionStorage.getItem("organization")}/auditlog?limit=10`).then(res => {
      let userData = res.data.results;
      var tempArr = [];
      var keyId = 0;
      userData.map((item, index) => {
        var eventType = ""
        keyId = keyId + 1
        eventType = (item.event_type + 'd').toLowerCase()
        tempArr.push(createAudit(keyId,item.user, eventType, item.content_type, item.object_repr, item.datetime))
      });
      setauditMsg(tempArr)
      }).catch(err => {
          console.log(err);
      });
    } 
    else{
      setauditMsg([])
    }
  }, []);
  // function handleNavButton() {
  //   setNotificationsMenu(null)
  //   history.push('/app/UserActivity')
  //  }
  function openProfile(){
    axios.get(`/${sessionStorage.getItem("organization")}/users/${sessionStorage.getItem("email")}`).then(res => {
      let userData = res.data;
      var dataArr = [];
      dataArr = createuserData(userData.first_name, userData.last_name, userData.user, userData.role)
      history.push({
        pathname: '/app/UserDetails',
        state: { detail: dataArr, fromPage: '/app/Dashboard' }
      });
    });
  } 

  return (
    <AppBar sx={{ background: 'linear-gradient(177deg,rgba(25,25,25,1), rgba(0,25,113, 1))' }} position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <span variant="h6" weight="medium" className={classes.logotype}>
          {enterpriseName}
        </span>
        <div className={classes.grow} />
        {isSalReadonly === false ?
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={null}
            color="red"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton> : null}
        {/* {isSalAdmin === true ? */}
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => openProfile()}
          //onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton> 
        {/* // : null} */}
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
        <div> 
             <div style={{marginLeft : '5%', fontSize : 18,fontWeight: "bold", fontFamily: "'Poppins', sans-serif",  marginTop: '3%', marginBottom: '3%', color :'rgb(7,71,166)'}}>  Notifications </div>
        </div>  
        <div style={{width:"350px",display:'inline-block'}} >           
        {auditMsg.length > 0 ?
          <div style={{marginTop:"-2%", paddingLeft: "2%"}} >
           
          {auditMsg.map(notification => (
            <MenuItem
              key={notification.id}
              //onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
           
            </MenuItem>
          ))}
          </div>
          :  <div style={{marginTop:"2%", height:"240px"}} >
            <Typography style={{marginTop :"5%", fontWeight: "bold", fontFamily: "'Poppins', sans-serif",  textAlign:"center", fontSize:"14px" }} > No Activity </Typography>
          </div>
        }
        </div>    
        </Menu>
      </Toolbar>
      <RebrandingBanner />
    </AppBar>
  );
}
