import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@mui/material";
import { useTheme } from "@mui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
import * as Icons from "@mui/icons-material";
import axios from "axios";
import { useUserDispatch, signOut } from "../../context/UserContext";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, type: 'divider' },
  { id: 1, label: 'Dashboard', link: '/app/dashboard', icon: <Icons.Dashboard style={{color:"white"}}/> },
  // { id: 1, type: 'divider' },
  { id: 2, label: 'Org Management', link: '/app/OrgManagement', icon: <Icons.Storage style={{color:"white"}} /> },
  // { id: 3, type: 'divider' },
  { id: 3, label: 'User Management', link: '/app/UserManagementEnterprise', icon: <Icons.SupervisorAccount style={{color:"white"}} /> },
  // { id: 3, label: 'Logout', link: '/login', icon: <Icons.ExitToApp />},
  { id: 4, type: 'divider' }
];
const signoutStructure = [
  { id: 5, label: 'Logout', link: '/login', icon: <Icons.ExitToApp style={{color:"white"}}/> }

]

function Sidebar({ location }, props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var layoutState = useLayoutState();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var isAlertShown = false
  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  axios.interceptors.response.use((response) => {
    // Return a successful response back to the calling service
    return response;
  }, (error) => {
    // console.log(error);
    if (error && error.response && error.response.status === 401) {
      if (isAlertShown === false) {
        isAlertShown = true
        alert("Signature expired");
        signOut(userDispatch, history)
      }
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  });

  return (

    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      sx={{
        "& .MuiDrawer-paper": {
          background:
            "linear-gradient(200deg,rgba(25,25,25, 1), rgba(0,25,113, 1))",
        },

      }}
      open={isSidebarOpened}
    >
      {/* <div className={classes.toolbar} /> */}
      <div style={{ padding: "8px" }} className={classes.mobileBackButton}>
        <IconButton
          size="large"
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <Icons.Close
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
              style={{ color: "#00FFEB" }}
            />
          ) : (
            <Icons.Dehaze
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
              style={{ color: "#00FFEB" }}
            />
          )}
        </IconButton>
      </div>
      {/* 
      <div className={classes.toolbar} />
      {isSidebarOpened === true ?
        <div className={classes.sidebarList}>
          <img style={{ marginLeft: "35%" }} src="SpaceAge-Icon-Grayscale.png" width="75" height="90" />
          <Typography style={{ marginTop: "5%", fontFamily: "'Poppins', sans-serif", textAlign: "center", fontWeight: "bold" }} >Hi, {sessionStorage.getItem("first_name")}</Typography>
        </div>
        : null} */}

      <div />
      {isSidebarOpened === true ?
        <div style={{ marginTop: "5%" }}>
          <img
            style={{ marginLeft: "6%", width:"auto" }}
            alt="icon"
            src="/icon.png"
            width="auto"
            height="40px"
          />
          <Typography
            style={{
              marginTop: "5%",
              marginLeft:"8%",
              color:"white",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "bold",
            }}
          >
            Hi, {sessionStorage.getItem("first_name")}
          </Typography>
        </div>
        : null}

      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
      <List className={classes.sidebarList} onClick={() => signOut(userDispatch, history)}>
        {signoutStructure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
      {/* <div className={classes.sidebarList}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div> */}
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
