import { makeStyles } from "@mui/styles";

const drawerWidth = 240;
//const drawerBackground = 'rgb(0, 82, 204)'
const drawerBackground = '#0066CC'

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    background : '#0066CC !important',
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflowY: 'overlay',
    background : '#0066CC !important',
    width: drawerWidth,
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  drawerClose: {
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    overflowX: "hidden",
    background : '#0066CC !important',
    width: "96px",
    // [theme.breakpoints.down("sm")]: {
    //   width: drawerWidth,
    // },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
   sidebarList: {
    marginTop: theme.spacing(0),
  }, 
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    // [theme.breakpoints.up("sm")]: {
    //   display: "none",
    // },
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: "rgb(7, 71, 166)",
      color: "white",
    },
  },
  headerMenuButton: {
    marginLeft: `${theme.spacing(2)} !important`,
    padding: `${theme.spacing(0.5)} !important`,
  },
  headerMenuButtonCollapse: {
    marginRight: `${theme.spacing(2)} !important`,
  },
}));
