import { makeStyles } from "@mui/styles";

export default makeStyles({
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.16)",
    },
  },
  linkActive: {
    backgroundColor: "rgba(255, 255, 255, 0.16)",
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: "#FFFFFF",
    },
  },
  linkIcon: {
    marginRight: "8px", // Replacing `theme.spacing(1)`
    color: "white",
    transition: "color 0.3s ease-in-out", // Custom CSS transition
    width: 24,
    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    color: "#2d4d76",
  },
  linkText: {
    padding: 0,
    marginTop: "4px !important",
    marginBottom: "4px !important",
    color: "white !important",
    fontWeight: "bold !important",
    fontFamily: "'Karla', sans-serif !important",
    transition: "opacity 0.3s ease-in-out, color 0.3s ease-in-out !important", // Custom CSS transition
    fontSize: "16px !important",
  },
  linkTextActive: {
    color: "white", // Use a hardcoded value for `theme.palette.text.white`
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: "46px", // Equivalent to `theme.spacing(2) + 30`
  },
  sectionTitle: {
    marginLeft: "36px", // Equivalent to `theme.spacing(4.5)`
    marginTop: "8px", // Equivalent to `theme.spacing(1)`
    marginBottom: "16px", // Equivalent to `theme.spacing(2)`
  },
  divider: {
    marginTop: "8px", // Equivalent to `theme.spacing(1)`
    marginBottom: "16px", // Equivalent to `theme.spacing(2)`
    height: 1,
    backgroundColor: "#D8D8D880",
  },
});
